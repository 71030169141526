import(/* webpackMode: "eager", webpackExports: ["AuthErrors"] */ "/vercel/path0/apps/web/src/app/[locale]/(auth)/login/components/auth-errors.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginWithEmailForm"] */ "/vercel/path0/apps/web/src/app/[locale]/(auth)/login/components/login-email-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginWithOIDC"] */ "/vercel/path0/apps/web/src/app/[locale]/(auth)/login/components/login-with-oidc.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SSOProvider"] */ "/vercel/path0/apps/web/src/app/[locale]/(auth)/login/components/sso-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
